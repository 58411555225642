import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "organisations-v3"
    }}>{`Organisations V3`}</h1>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`Organisation register ("organisations") is a service for creating, storing and retrieving organisations referred to by Entur services.`}</p>
    <h2 {...{
      "id": "model"
    }}>{`Model`}</h2>
    <p><img alt="Domain model" src={require("./diagrams/model-v3.png")} /></p>
    <h3 {...{
      "id": "organisation"
    }}>{`Organisation`}</h3>
    <p>{`Data that does not change for an organisation. Ie. organisationnumber and IDs.
Organisation represents a legal entity, such as a company or a public authority in EnTur context.
An organisation has a validity period, and can be replaced, deactivated, split, or merged.`}</p>
    <h3 {...{
      "id": "organisationversion"
    }}>{`OrganisationVersion`}</h3>
    <p>{`If an organisation is updated, a new OrganisationVersion is created with the new data and the old version is stored.
When fetching the current organisation the one with the highest version number it retrieved.
Data that can be changed for an organisation includes contact information, trading name, legal name etc.`}</p>
    <h3 {...{
      "id": "organisationscodespace"
    }}>{`OrganisationsCodespace`}</h3>
    <p>{`Relationship between organisation and codespace. This is a `}<em parentName="p">{`many-to-many`}</em>{` relationship. An organisation can have multiple codespaces, and a codespace can have multiple organisations.
OrganisationCodespacces can be versioned.`}</p>
    <h3 {...{
      "id": "organisationshistory"
    }}>{`OrganisationsHistory`}</h3>
    <p>{`Historic organisations. If an organisation is replaced, deleted, split, or merged, a reference to the old organisation(s) is created in the history table.
This can be used to trace the origins of an organisation in case of changes.`}</p>
    <h3 {...{
      "id": "organisationsalias"
    }}>{`OrganisationsAlias`}</h3>
    <p>{`To retrieve an organisation internally, use internalId. Externally, use masterId. Alias can be used as a text identifier in a transitional phase to refer
to an organisation instead of internalId/masterId in cases where internalId/masterId of the organisation is unknown. After retrieval, inspect the returned organisation to get the correct ID to use.
Multiple aliases can point to the same organisation. Aliases are versioned.`}</p>
    <h3 {...{
      "id": "use-cases"
    }}>{`Use Cases`}</h3>
    <p>{`See the `}<a parentName="p" {...{
        "href": "api-v3.mdx"
      }}>{`API documentation`}</a>{` for supported use cases.`}</p>
    <h2 {...{
      "id": "usage-context"
    }}>{`Usage Context`}</h2>
    <p>{`Organisation API is used by Team Sales, Team Customers, Team Route, Entur Partner WebUI, and Mobile & Web Apps.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      